<template>
  <div class="landing-layout">
    <transition name="fade">
      <StickyNotification v-if="Boolean(sticky._id)" :item="sticky" is-public />
    </transition>
    <div class="background" />
    <LandingMenu
      class="hide-desktop"
      :is-scrolling="isScrolling"
      :login-step="merchantLogin.step"
    />

    <CustomScroll :class="{ 'full-height': noScroll }" @scroll-top="(val) => isScrolling = val > 20">
      <LandingMenu
        class="hide-mobile"
        :login-step="merchantLogin.step"
      />

      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </CustomScroll>
  </div>
</template>

<script setup>
import {
  computed, onBeforeMount, ref, watch,
} from 'vue';

import StickyNotification from '@/components/Modules/StickyNotification.vue';

import { useRoute } from '@/router';
import { useStickies } from '@/composables/useStickies';
import { getPublicStickies } from '@/api/stickies';
import { useTemporaryStore } from '@/store';

import LandingMenu from './components/LandingMenu.vue';

const route = useRoute();
const isMenuOpened = ref(false);

watch(route, () => {
  isMenuOpened.value = false;
});

watch(isMenuOpened, (value) => {
  if (value) {
    document.body.classList.add('is-scroll-prevented');
  } else {
    document.body.classList.remove('is-scroll-prevented');
  }
});

onBeforeMount(() => {
  getPublicStickies();
});

const { store } = useStickies();
const sticky = computed(() => store.getVisitorsSticky());

const noScroll = computed(() => route.name === 'LANDING');

const isScrolling = ref(false);

// eslint-disable-next-line no-underscore-dangle
const paddingTopPage = computed(() => (sticky.value?._id ? '120px' : '80px'));
// eslint-disable-next-line no-underscore-dangle
const paddingTopMenu = computed(() => (sticky.value?._id ? '40px' : '0'));

const { merchantLogin } = useTemporaryStore();
</script>

<style scoped lang="scss">
.landing-layout {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include mq('tablet') {
    padding-top: v-bind('paddingTopPage');

    :deep(.menu) {
      padding-top: v-bind('paddingTopMenu');
    }
  }

  @media (max-height: 414px) {
    padding-top: 60px !important;
  }

  .hide-desktop {
    display: none;

    @include mq('tablet') {
      display: grid;
    }
  }

  .hide-mobile {
    display: grid;

    @include mq('tablet') {
      display: none;
    }
  }
}

.background{
  background: url("../../assets/images/bg_about.svg") no-repeat center center;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
  z-index: -1;
  background-position: top;

  @include mq('mobile') {
    position: absolute;
  }
}

:deep(.simplebar-content) {
  height: 100%;
}
</style>
