<template>
  <div class="mobile-menu">
    <div class="menu-links">
      <router-link class="menu-link" to="/">
        {{ t('landing.labels.home') }}
      </router-link>

      <router-link class="menu-link" to="/about">
        {{ t('landing.header.about') }}
      </router-link>

      <a
        class="menu-link menu-link-external"
        :href="link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ t('landing.labels.documentation') }} <AppIcon name="external-link" size="14px" />
      </a>
    </div>

    <div class="soc">
      <a
        class="soc-link"
        :href="emailLink"
        rel="noopener noreferrer"
        target="_blank"
      >
        <AppIcon class="mail-icon" name="mail" size="18px" /><span>{{ t('landing.labels.support') }}</span>
      </a>

      <a
        class="soc-link"
        href="https://twitter.com/switch767"
        rel="noopener noreferrer"
        target="_blank"
      >
        <AppIcon class="tw-icon" name="twitter" size="18px" /><span>Twitter</span>
      </a>

      <a
        class="soc-link"
        href="https://t.me/switch_to"
        rel="noopener noreferrer"
        target="_blank"
      >
        <AppIcon class="tg-icon" name="telegram" size="18px" /><span>Telegram</span>
      </a>
    </div>

    <SelectLanguageAcc />
  </div>
</template>

<script setup>
import SelectLanguageAcc from '@/components/SelectLanguage/SelectLanguageAcc.vue';

const uri = process.env.VUE_APP_PRODUCTION_URL;
const link = `https://${uri}`;
const email = `support@${uri}`;
const emailLink = `mailto:${email}`;
</script>

<style lang="scss" scoped>
.mobile-menu {
  min-width: 180px;
  color: var(--color-white);
  background: var(--color-2B1C2EEA);
  padding-bottom: 10px;

  .menu-links {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--color-E6E6E61A);
    padding: 10px 0;

    .menu-link {
      color: var(--color-white);
      text-decoration: none;
      font-size: 13px;
      line-height: 2.3;
      padding: 0 20px;
      cursor: pointer;
      @include font-medium;
      @include transition-base('color');

      &:hover {
        color: var(--color-primary);

        &.menu-link-external {
          :deep(.icon path) {
            fill: var(--color-primary);
          }
        }
      }

      &-external {
        display: flex;
        align-items: center;
        gap: 5px;

        :deep(.icon path) {
          fill: var(--color-white-05);
          @include transition-base('fill');
        }
      }
    }
  }

  .soc {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    .soc-link {
      height: 30px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--color-white-05);
      cursor: pointer;
      font-size: 13px;
      @include transition-base('color');

      :deep(.icon) {
        fill: var(--color-white-05);
        @include transition-base('fill');
      }

      &:hover {
        color: var(--color-white);

        :deep(.icon) {
          & > g > path {
            stroke: var(--color-white);
          }

          & > path {
            fill: var(--color-white);
          }
        }
      }
    }
  }

  :deep(.lang-acc) {
    margin: 0 10px;
  }
}
</style>
