<template>
  <div class="menu" :class="{ bg: isScrolling }">
    <div class="left-part" :class="{ hide: isBlockHide }">
      <div v-if="isBackButtonVisible" class="hamb" @click="onClickBack">
        <AppIcon
          size="18px"
          name="arrow-basic"
          class="rotate-270"
          :opacity="0.7"
        />
      </div>

      <FDropdown
        v-else
        class="hamb-wrapper"
        placement="bottom-start"
        no-styles
        block-class="hamb-popper"
        @visible-change="onMenuChange"
      >
        <template #title>
          <div class="hamb" :class="[animationClass]">
            <AppIcon
              size="20px"
              name="menu"
            />
          </div>
        </template>

        <template #content>
          <CustomScroll class="mobile-menu-scroll" is-dark>
            <MobileMenu />
          </CustomScroll>
        </template>
      </FDropdown>

      <router-link
        to="/about"
        :class="{ 'is-visited': currentPath === '/about' }"
        class="menu-item about font-semibold"
        active-class="active"
      >
        {{ t('landing.header.about') }}
      </router-link>

      <SelectContact>
        {{ t('landing.header.more') }}
      </SelectContact>
    </div>

    <div class="logo">
      <router-link to="/" :aria-label="t('ariaLabel.home')" class="logo-link">
        <AppIcon
          size="87px"
          name="switch-logo"
          fill="var(--color-primary)"
          :class="{ gray: loginStep !== 'account' && deviceType === 'mobile' }"
        />
      </router-link>
    </div>

    <div class="right-part" :class="{ hide: isBlockHide }">
      <SelectLanguage class="language-container">
        <template #title="{ languageObject, isOpen }">
          <LangTitleBasic :lang="languageObject?.text" :is-open="isOpen" />
        </template>
      </SelectLanguage>

      <AppTooltip
        :is-enabled="!isSignUpEnabled"
        text-align="center"
        width="252px"
      >
        <template #text>
          {{ t('landing.labels.registrationDisabled') }}
        </template>

        <router-link
          :to="isSignUpEnabled ? '/sign-up' : ''"
          class="menu-item register-link font-semibold"
          :class="{ disabled: !isSignUpEnabled }"
          :aria-label="t('ariaLabel.signup')"
        >
          {{ t('landing.header.sign') }}
        </router-link>
      </AppTooltip>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useWindowSize } from '@vueuse/core';

import LangTitleBasic from '@/components/SelectLanguage/components/LangTitleBasic.vue';
import SelectLanguage from '@/components/SelectLanguage';
import AppTooltip from '@/components/AppTooltip.vue';
import CustomScroll from '@/components/CustomScroll.vue';

import { emitter } from '@/composables/useBus';
import { useRoute } from '@/router';
import { useStore } from '@/store';

import MobileMenu from './MobileMenu.vue';
import SelectContact from './SelectContact.vue';

const props = defineProps({
  isScrolling: {
    type: Boolean,
    default: false,
  },
  loginStep: {
    type: String,
    default: 'account',
  },
});

const store = useStore();

const isSignUpEnabled = computed(() => store.preferences?.isSignUpEnabled);

const route = useRoute();
const currentPath = computed(() => route.path);

// Null for disable animation on first render
const isMobileMenuOpen = ref(null);
const onMenuChange = (val) => {
  isMobileMenuOpen.value = val;
  emitter.emit('mobile-menu-change', val);
};

const animationClass = computed(() => {
  if (isMobileMenuOpen.value === null) return '';
  return isMobileMenuOpen.value ? 'menu-open' : 'menu-close';
});

const onClickBack = () => {
  emitter.emit('menu-back');
};

const isBackButtonVisible = computed(() => !!(deviceType.value === 'mobile' && props.loginStep !== 'account'));
const isBlockHide = computed(() => props.loginStep === 'detonation' && deviceType.value === 'mobile');

const { width } = useWindowSize();

const deviceType = computed(() => {
  switch (true) {
    case width.value < 768:
      return 'mobile';
    case width.value >= 768 && width.value < 1069:
      return 'tablet';
    default:
      return 'pc';
  }
});
</script>

<style lang="scss" scoped>
@media (max-height: 414px) {
  .mobile-menu-scroll {
    max-height: 180px;
  }
}

.menu {
  display: grid;
  grid-template-columns: 1fr 87px 1fr;
  grid-template-rows: 120px;
  align-items: center;
  overflow: visible;

  @media (max-height: 414px) {
    grid-template-rows: 60px !important;
  }

  .hide {
    opacity: 0;
    pointer-events: none;
    @include transition-base('opacity');
  }

  @include mq('tablet') {
    grid-template-columns: 1fr 67px 1fr;
    grid-template-rows: 100px;
    padding: 0 40px;
    position: fixed;
    width: 100%;
    z-index: 5;
    top: 0;

    &.bg {
      opacity: 1;
      background: var(--color-white);
      box-shadow: 0 1px 8px var(--color-black-01);
      transition: opacity 2s;
    }
  }

  @include mq('mobile') {
    grid-template-columns: 1fr 45px 1fr;
    grid-template-rows: 80px;
    padding: 0 20px;
  }

  .logo {
    width: 87px;
    height: 41px;

    @include mq('tablet') {
      width: 67px;
      height: 32px;
    }

    @include mq('mobile') {
      width: 45px;
      height: 21px;
    }

    .gray {
      :deep(path) {
        fill: var(--color-black-02);
        @include transition-base('fill');
      }
    }

    .logo-link {
      width: inherit;
      height: inherit;
      display: block;

      @include focus-visible;

      :deep(.icon) {
        width: inherit;
        height: inherit;

        path {
          @include transition-base('fill');
        }
      }
    }

    &:hover {
      :deep(.icon path) {
        fill: var(--color-black);
      }
    }
  }

  .menu-item {
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--color-black);
    font-size: 14px;
    line-height: 1.2;

    @include focus-visible;
  }

  .left-part {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    padding-right: 80px;

    @include mq('pc') {
      :deep(.hamb-wrapper .f-dropdown--title:focus-visible) {
        outline: unset !important;
      }
    }

    @include mq('tablet') {
      padding: 0;
      justify-content: flex-start;
    }

    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    .hamb {
      display: none;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--color-black-005);

      &:hover {
        background: var(--color-black-01);
      }

      &.menu-open {
        :deep(.menu-top-line) {
          animation: 0.1s linear both yz9y4joa2cywgdgk9g0ho28y_t;
        }

        :deep(.menu-bottom-line) {
          animation: 0.1s linear both twsaaf78wtb510bmtdcweo86_t;
        }
      }

      &.menu-close {
        :deep(.menu-top-line) {
          animation: 0.1s linear both du9sy5xf0sayjevtx6x3g9d3_t;
        }

        :deep(.menu-bottom-line) {
          animation: 0.1s linear both npp5g6vuas20iz5k10wx447c_t;
        }
      }

      @include mq('tablet') {
        @include flex-center;
      }

      @keyframes yz9y4joa2cywgdgk9g0ho28y_t { 0% { transform: translate(10px,14px) rotate(0deg) translate(-10px,-14px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 100% { transform: translate(10px,10px) rotate(-45deg) translate(-10px,-14px); } }
      @keyframes twsaaf78wtb510bmtdcweo86_t { 0% { transform: translate(10px,7px) rotate(0deg) translate(-10px,-7px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 100% { transform: translate(10px,10px) rotate(45deg) translate(-10px,-7px); } }

      @keyframes du9sy5xf0sayjevtx6x3g9d3_t { 0% { transform: translate(10px,10px) rotate(-45deg) translate(-9px,-12px); } 100% { transform: translate(10px,14px) rotate(0deg) translate(-10px,-14px); } }
      @keyframes npp5g6vuas20iz5k10wx447c_t { 0% { transform: translate(10px,10px) rotate(45deg) translate(-9px,-6px); } 100% { transform: translate(10px,7px) rotate(0deg) translate(-10px,-7px); } }
    }

    // Router active class fix
    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    .about {
      text-transform: uppercase;
      @include transition-base('color, background');

      &:hover:not(&.active) {
        background: var(--color-black-005);
        border-radius: 4px;
      }

      &.active {
        color: var(--color-black-04);
        cursor: default;
      }

      @include mq('tablet') {
        display: none;
      }
    }
  }

  .right-part {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    padding-left: 80px;

    @include mq('tablet') {
      padding-left: 0;
    }

    .register-link {
      background: var(--color-black-005);
      border-radius: 99px;
      height: 36px;

      &.disabled {
        color: rgba(0, 0, 0, 0.2);
        cursor: default;
      }

      @include mq('mobile') {
        height: 32px;
        font-size: 13px;
      }

      &:hover {
        background: var(--color-black-01);
      }
    }

    @include mq('tablet') {
      justify-content: flex-end;
    }
  }
}

.language-container {
  @include mq('tablet') {
    display: none;
  }

  :deep(.f-dropdown--title) {
    height: 36px;
  }
}

:deep(.simplebar-content) {
  background-color: var(--color-2B1C2EEA);
}

:deep(.contact-select) {
  @include mq('tablet') {
    display: none;
  }
}
:global(.hamb-popper > .el-scrollbar) {
  border: none !important;
}
</style>
