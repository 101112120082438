<template>
  <FDropdown
    class="contact-select"
    block-class="desktop-contacts-menu"
    type="small"
    @visible-change="isOpen = $event"
  >
    <template #title>
      <div class="d-flex align-items-center">
        <div class="contact-title">
          <slot />
        </div>
        <AppText
          type="uppercase"
          size="14px"
          mr="4px"
        />
        <DropdownArrow :is-open="isOpen" />
      </div>
    </template>
    <template #content>
      <div class="contact-content">
        <div class="contact-links">
          <a :href="emailLink" rel="noreferrer" class="contact-links-item link">
            <div class="icon-wrap">
              <AppIcon name="safe-circle" size="40px" is-img-tag />
            </div>
            <div class="links-content">
              <div class="links-title">{{ email }} <AppIcon name="external-link" size="14px" is-img-tag /></div>
              <div class="links-description">{{ t('landing.labels.openTicket') }}</div>
            </div>
          </a>
          <a
            :href="link"
            rel="noopener noreferrer"
            class="contact-links-item link"
            target="_blank"
          >
            <div class="icon-wrap">
              <AppIcon name="book-open" size="40px" is-img-tag />
            </div>
            <div class="links-content">
              <div class="links-title">{{ t('landing.labels.documentation') }} <AppIcon name="external-link" size="14px" is-img-tag /></div>
              <div class="links-description">{{ t('common.learnMore') }}</div>
            </div>
          </a>
        </div>
        <div class="divider" />
        <div class="contact-social">
          <a
            href="https://t.me/switch_to"
            rel="noopener noreferrer"
            class="contact-social-item link"
            target="_blank"
          >
            <AppIcon name="telegram" size="20px" />
            Telegram
          </a>
          <a
            href="https://twitter.com/switch767"
            rel="noopener noreferrer"
            class="contact-social-item link"
            target="_blank"
          >
            <AppIcon name="twitter" size="20px" />
            Twitter
          </a>
          <a
            :href="feedbackLink"
            rel="noopener noreferrer"
            class="contact-social-item link"
            target="_blank"
          >
            <AppIcon name="chat-message" size="20px" />
            {{ t('landing.labels.feedback') }}
          </a>
        </div>
      </div>
      <div class="contact-footer-wrap">
        <div class="contact-footer" @click.stop>
          <AppIcon name="tor" size="18px" />
          <TooltipCopy :text="torLink.value" :tooltip-text="t('landing.labels.copyTorAddress')">
            <AppText size="14px">
              {{ torLink.short }}
            </AppText>
          </TooltipCopy>
        </div>
      </div>
    </template>
  </FDropdown>
</template>

<script setup>
import { ref } from 'vue';

import TooltipCopy from '@/components/TooltipCopy.vue';
import DropdownArrow from '@/components/Icon/DropdownArrow.vue';

import { ONION_URL, useApp } from '@/composables/useApp';

const { truncatedOnionUrl } = useApp();

const torLink = {
  value: ONION_URL,
  short: truncatedOnionUrl,
};

const uri = process.env.VUE_APP_PRODUCTION_URL;
const link = `https://${uri}`;
const feedbackLink = `https://feedback.${uri}`;
const email = `support@${uri}`;
const emailLink = `mailto:${email}`;

const isOpen = ref(false);
</script>

<style scoped lang="scss">
.contact-select  {
  height: 32px !important;

  .contact-title {
    text-transform: uppercase;
    font-size: 14px;
    @include font-semibold;
  }

  .contact-content {
    padding: 30px;
  }
}

@media screen and (max-width: $mediaTablet) {
  .contact-select {
    display: none !important;
  }
}

.contact-content {
  padding: 20px;
  display: flex;
  .divider {
    width: 1px;
    height: 116px;
    background: var(--color-E6E6E6);
    align-self: flex-end;
  }
  .link {
    padding: 0 10px;
    @include transition-base('background');

    .icon-wrap {
      position: relative;

      &::after {
        box-sizing: content-box;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 36px;
        height: 36px;
        border: 2px solid transparent;
        border-radius: 8px;
        @include transition-base('border');
      }
    }

    &:hover {
      background: var(--color-black-003);
      cursor: pointer;

      .icon-wrap::after {
        border: 2px solid var(--color-white);
      }
    }
  }
}

.contact-links{
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-right: 20px;
  &-item {
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: 250px;
    height: 60px;

    :deep(.icon) {
      margin-right: 20px;
    }
  }
  .links-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
    .links-title {
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 1.2;
      :deep(.icon) {
        margin-left: 6px;
      }
    }
    .links-description {
      font-size: 13px;
      line-height: 1.2;
      color: var(--color-black-04)
    }
  }
}

.contact-social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  font-size: 13px;
  line-height: 1.2;
  padding-top: 4px;
  padding-left: 35px;
  padding-right: 10px;
  &-item {
    min-width: 145px;
    height: 32px;
    display: flex;
    align-items: center;
    color: var(--color-black-05) !important;
    border-radius: 4px;
    @include transition-base(color);
    :deep(.icon) {
      margin-right: 12px;
      path {
        @include transition-base(fill);
      }
    }
    &:hover {
      background-color: var(--color-black-003);
      color: var(--color-black) !important;
      :deep(.icon) {
        path {
          fill: var(--color-black);
        }
      }
    }
  }
}

.contact-footer-wrap {
  border-top: 1px solid var(--color-black-01);
  height: 50px;
  padding: 0 30px;
  display: flex;
  align-items: center;

  .contact-footer {
    display: flex;
    align-items: center;
    gap: 12px;

    :deep(.icon) {
      path {
        fill: var(--color-black-04);
        @include transition-base('fill');
      }
    }

    :deep(.text) {
      color: var(--color-black-04);
      @include transition-base('color');
    }

    &:hover {
      :deep(.icon) {
        opacity: 1;
        path {
          fill: var(--color-black);
        }
      }

      :deep(.text) {
        color: var(--color-black);
      }
    }
  }
}

:global(.desktop-contacts-menu) {
  border-radius: 12px !important;
}

:global(.desktop-contacts-menu .el-scrollbar) {
  border-radius: 12px !important;
}
</style>
